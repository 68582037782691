import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://apw.${config.devbase}`
config.prodURL = 'https://cms.aussiepoochwholesale.com.au'
config.siteURL = 'https://aussiepoochwholesale.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'

config.isEcommerce = true

config.profile.edit_company_name = true
config.profile.edit_abn = true
config.sentryDsn = 'https://43733039c49e4612a9da5c61be080b0b@glitchtip.logger.jm1.au/6'

export { config }
