<template>
  <div class="space-y-3">
    <div v-for="variation in variations" :key="variation.name" class="flex flex-col">
      <cgn-form-dropdown
        v-model="variation.selected"
        :label="variation.name"
        :options="variation.options"
      />
    </div>
    <div v-for="addon in addons" :key="addon.id" class="flex flex-col">
      <cgn-form-checkbox v-if="addon.type == 'Checkbox'" v-model="addon.selected" :label="addon.name" />
      <cgn-form-dropdown
        v-if="addon.type == 'Dropdown'"
        v-model="addon.selected"
        :label="addon.name"
        :options="addon.options"
      />
      <cgn-form-input
        v-if="addon.type == 'Qty'"
        v-model="addon.selected"
        type="number"
        :min-amount="addon.min_qty"
        :max-amount="addon.max_qty"
        :label="addon.name"
      />
      <cgn-form-input v-if="addon.type == 'Text'" v-model="addon.selected" :label="addon.name" />
    </div>
    <cgn-button v-if="!isLoggedIn" color-brand redirect-login>
      Log in to see prices
    </cgn-button>
    <div v-else>
      <cgn-form-input v-model="qty" label="Qty" type="number" min-amount="1" />
      <div>
        <div
          v-if="price_each"
          class="font-medium text-xl"
          :class="on_special ? 'text-red-400 dark:text-red-600' : 'text-gray-700 dark:text-gray-100'"
        >
          ${{ price_each }}
        </div>
        <div
          v-if="on_special"
          class="line-through text-xs font-medium text-gray-500 dark:text-gray-400"
        >
          ${{ full_price_each }}
        </div>
      </div>
      <cgn-alert-danger v-if="!in_stock && selected_sku > 0">
        Out of stock
      </cgn-alert-danger>
      <cgn-alert-danger v-if="selected_sku == 0">
        Select options
      </cgn-alert-danger>
      <cgn-alert-success v-if="added_to_cart">
        Added to cart
      </cgn-alert-success>
      <div>
        <cgn-button
          v-if="show_checkout"
          color-secondary
          url="/checkout"
        >
          Checkout now
        </cgn-button>
      </div>
      <div>
        <cgn-button v-if="selected_sku > 0" color-brand @click="addToCart">
          <span class="flex flex-row gap-2 items-center">
            Add to cart
            <cgn-spinner v-if="adding_to_cart" />
          </span>
        </cgn-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~cognito/stores/cart'
import { SellProduct } from '~cognito/models/Sell/Product'

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
})

const cartStore = useCartStore()

const selected_sku = ref(0)
const price_each = ref('')
const full_price_each = ref('')
const on_special = ref(false)
const in_stock = ref(true)
const qty = ref(1)
const variations = ref(props.product.variations)
const addons = ref(props.product.addons)

const adding_to_cart = ref(false)
const added_to_cart = ref(false)
const show_checkout = ref(false)

const selected_addons = computed<{
  id: number
  value: string
}[]>(() => {
  const selected_addons = []
  for (let index = 0; index < addons.value.length; index++) {
    const addon = addons.value[index]
    if (addon.selected) {
      selected_addons.push({
        id: addon.id,
        value: addon.selected,
      })
    }
  }
  return selected_addons
})

const selected_variations = computed<{
  name: string
  value: string
}[]>(() => {
  const selected_variations = []
  if (!variations.value) {
    return []
  }
  for (let index = 0; index < variations.value.length; index++) {
    const variation = variations.value[index]
    if (variation.selected) {
      selected_variations.push({
        name: variation.name,
        value: variation.selected,
      })
    }
  }
  return selected_variations
})

const isLoggedIn = computed(() => useUserStore().isLoggedIn())

function searchsku() {
  if (!props.product.id) {
    return
  }
  if (!isLoggedIn.value) {
    return
  }
  new SellProduct().skusearch({
    product_id: props.product.id,
    variations: selected_variations.value,
    addons: selected_addons.value,
  })
    .then((data) => {
      selected_sku.value = data.sku
      price_each.value = data.price_each
      full_price_each.value = data.full_price_each
      on_special.value = data.on_special
      in_stock.value = data.in_stock
    })
}

watch(() => [
  variations,
  addons,
  props.product,
], () => {
  searchsku()
}, { deep: true })

async function addToCart() {
  adding_to_cart.value = true
  await cartStore.addToCart(selected_sku.value, qty.value, selected_addons.value)
  adding_to_cart.value = false
  added_to_cart.value = true
  show_checkout.value = true
  setTimeout(() => {
    added_to_cart.value = false
  }, 3000)
}
onMounted(() => {
  searchsku()
})
</script>
