<template>
  <div
    class="relative"
  >
    <cgn-breadcrumb :links="breadcrumbs" />
    <div class="gap-10 px-4 pb-20 pt-16 sm:flex sm:px-6 lg:px-8 lg:pb-28 lg:pt-12">
      <div class="relative mx-auto max-w-7xl">
        <div class="text-center">
          <h1
            class="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl"
          >
            From the blog
            <div class="text-2xl font-bold text-brand-500 dark:text-brand-400 sm:text-3xl">
              {{ groupDetails.name }}
            </div>
          </h1>
          <div class="prose-brand prose dark:prose-dark" v-html="groupDetails.content" />
        </div>
        <div class="mx-auto mt-12 grid max-w-lg gap-5 md:max-w-none md:grid-cols-2 lg:grid-cols-3">
          <div
            v-for="post, index in posts"
            :key="index"
            class="flex flex-col overflow-hidden rounded-lg shadow-lg"
          >
            <div class="shrink-0">
              <router-link :to="`/article/${post.slug}`">
                <cgn-lazy-image
                  class="w-full"
                  :image="post.image"
                />
              </router-link>
            </div>
            <div class="flex flex-1 flex-col justify-between bg-white p-6 dark:bg-darkbg-500">
              <div class="flex-1">
                <router-link :to="`/article/${post.slug}`" class="mt-2 block">
                  <p class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ post.name }}
                  </p>
                  <div class="mt-3 text-base text-gray-500 dark:text-gray-200" v-html="post.blurb" />
                </router-link>
              </div>
              <div class="mt-6 flex items-center">
                <div class="ml-3">
                  <p class="text-sm font-medium text-gray-900 dark:text-gray-100">
                    {{ post.author }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex grow justify-center py-5">
          <cgn-pagination
            :current-page="props.page"
            :page-count="num_pages"
            :group="props.group"
            url-prefix="blog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { CognitoArticle } from '~cognito/models/Cognito/Article'

const props = defineProps({
  group: {
    type: String,
    default: 'page',
  },
  page: {
    type: Number,
    default: 1,
  },
})

const posts = ref<CognitoArticle[]>([])
const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const num_pages = ref(0)

const breadcrumbs = ref([{ name: 'Articles', url: '/blog' }])

async function loadArticles() {
  posts.value = []
  num_pages.value = 1
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }
  breadcrumbs.value = [{ name: 'Articles', url: '/blog' }]
  const data = await new CognitoArticle().find_many({
    image_aspect: '4x3',
    image_width: 150,
    page_size: 9,
    orderby: 'display_start_time',
    page,
    group,
  })
  posts.value = data.data
  num_pages.value = data.num_pages

  groupDetails.value = new CognitoGroup()
  if (group) {
    // Need the group to get heading etc
    const data = await new CognitoGroup().find_one({
      namespace: 'Cognito',
      model: 'Article',
      group,
    })
    groupDetails.value = data
    breadcrumbs.value.push({ name: data.name, url: group })
  }
}
watch(() => props, () => {
  loadArticles()
}, {
  deep: true,
})
onMounted(() => {
  loadArticles()
})
onServerPrefetch(async () => {
  await loadArticles()
})
</script>
